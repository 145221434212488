import { useContext } from 'react'
import { makeStyles } from '@material-ui/core'
import { List as ListIcon } from '@material-ui/icons'
import Card from 'components/Card/Card'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import CardBody from 'components/Card/CardBody'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import ExcelDefaultExport from 'components/ReactTable/components/ExcelDefaultExport'
import ReportLogin from './ReportLogin'
import ReportInvestments from './ReportInvestments'
import { FilterReactTableContext } from 'contexts/filterReactTableContext'
import { FilterReactVirtualizedTableContext } from 'contexts/filterReactVirtualizedTableContext'
import { reportScreenLoginColumns } from '../helpers/reportScreenLoginColumns'
import { excelMapper } from '../mappers/reportScreenMapper'
import styles from '../styles/reportScreenStyles'

const useStyles = makeStyles(styles)

const ReportBody = ({ isErrorType, data, filter, value }) => {
	const classes = useStyles()
	const tableId = 100

	const { filteredRows } = useContext(FilterReactTableContext)
	const { getFilteredDataRows } = useContext(FilterReactVirtualizedTableContext) //context of table

	return (
		<GridContainer>
			<GridItem xs={12}>
				<Card>
					<CardHeader color='primary' icon>
						<CardIcon color='primary'>
							<ListIcon />
						</CardIcon>
					</CardHeader>
					<CardBody>
						<GridContainer>
							<GridItem xs={12}>
								{value === 'investment' && (
									<GridContainer className={classes.gridContainer}>
										<GridItem md={12} sm={12} xs={12} className={classes.gridItem}>
											{data.length > 0 && (
												<ExcelDefaultExport excelName='Historial de actividades inversiones' data={filteredRows} />
											)}
											{isErrorType !== 'action' && <ReportInvestments data={data} type={isErrorType} filter={filter} />}
										</GridItem>
									</GridContainer>
								)}
								{value === 'login' && (
									<GridContainer className={classes.gridContainer}>
										<GridItem md={12} sm={12} xs={12} className={classes.gridItem}>
											{Boolean(getFilteredDataRows({ tableId })?.length) && (
												<ExcelDefaultExport
													excelName='Historial de actividades login'
													tableColumns={reportScreenLoginColumns({ tableId })}
													data={excelMapper({ filteredRows: getFilteredDataRows({ tableId }) })}
												/>
											)}
											{isErrorType !== 'action' && <ReportLogin data={data} type={isErrorType} />}
										</GridItem>
									</GridContainer>
								)}
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>
			</GridItem>
		</GridContainer>
	)
}

export default ReportBody
