import { useSelector } from 'react-redux'
import ReactVirtualizedTable from 'components/ReactVirtualizedTable/ReactVirtualizedTable'
import { reportScreenLoginColumns } from '../helpers/reportScreenLoginColumns'

const ReportLogin = ({ data, type }) => {
	const { successLogSearch, loadingLogSearch, errorLogSearch } = useSelector(({ logSearch }) => logSearch)
	return (
		<ReactVirtualizedTable
			tableId={100}
			data={data}
			columns={reportScreenLoginColumns({ type })}
			loading={Boolean(loadingLogSearch)}
			success={Boolean(successLogSearch)}
			error={errorLogSearch}
		/>
	)
}

export default ReportLogin
