import { format } from 'date-fns'
import { matchRoleName } from 'shared/matchData/matchData'

export const reportScreenMapper = ({ logSearchData, value }) => {
	if (value === 'investment') {
		return logSearchData.map((item) => {
			return {
				id: item._id,
				projectAddress: item.project?.projectAddress,
				user: item.user?.name,
				profile: item?.investors?.businessName,
				mount: item?.investment?.amount,
				error: item?.type?.error?.errorMessage,
				reason: item?.reason || '',
				date: item.createdAt,
			}
		})
	} else {
		return logSearchData.map((item) => {
			return {
				id: item._id,
				email: item.user.email,
				name: item?.user?.name,
				surname: item?.user?.lastName || '-----',
				role: matchRoleName(item?.user?.role),
				error: item?.type?.error?.errorMessage || '-----',
				date: item.createdAt,
				hour: item.createdAt,
			}
		})
	}
}

export const excelMapper = ({ filteredRows }) => {
	return filteredRows.map((item) => {
		return {
			id: item.id,
			email: item.email,
			name: item.name,
			surname: item.surname,
			role: item.role,
			error: item.error,
			date: format(new Date(item?.date), 'dd/MM/yyyy'),
			hour: format(new Date(item?.hour), 'hh:mm aaa'),
		}
	})
}
